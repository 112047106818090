



















































































































import {
  Component,
  Ref,
  Vue,
} from 'vue-property-decorator';
import vue2Dropzone from 'vue2-dropzone';

import { formatErrorForNotification } from '@/utils/error';
import Breadcrumbs from '@/components/defaults/Breadcrumbs.vue';
import PageContainer from '@/components/defaults/PageContainer.vue';
import PayrollRepository from '@/repositories/PayrollRepository';
import VanRepository from '@/repositories/VanRepository';
import IOption from '@/domain/interfaces/ISelectOptions';
import BankConfig from '@/domain/models/BankConfig';

@Component({
  components: {
    VueDropzone: vue2Dropzone,
    Breadcrumbs,
    PageContainer,
  },
})
export default class PayrollUpload extends Vue {
  @Ref('payrollUpload') dropzone!: any;

  readonly payrollRepository: PayrollRepository = new PayrollRepository();
  readonly vanRepository: VanRepository = new VanRepository();

  companies: Array<IOption<number>> = [];
  company: number = 0;
  loadingCompanies: boolean = false;

  banks: Array<IOption<string>> = [];
  vans: IOption<number>[] = [];

  bank: string = '';
  selectedVan: number = 0;

  bankConfigsLoaded: Map<string, BankConfig> = new Map();
  loadingBanks: boolean = false;
  loadingVans: boolean = false;
  paymentDate = '';
  shouldUsePaymentDate = false;

  accountsPayable: Array<string> = [];

  dropzoneOptions = {
    url: () => `${this.$store.state.API_BASE}company-groups/${this.groupId}/payrolls/accounts-payable`,
    paramName: 'payroll_file',
    duplicateCheck: true,
    uploadMultiple: false,
    acceptedFiles: '.txt, .rem',
    parallelUploads: 1,
    dictRemoveFile: 'Remover',
    dictInvalidFileType: 'Tipo do arquivo inválido!',
  };

  get groupId(): number {
    return this.$session.get('company_group_id');
  }

  async mounted() {
    await this.loadData();
    this.getLocalStorage();

    const { accountsPayable } = this.$route.params;

    this.accountsPayable = accountsPayable && Array.isArray(accountsPayable)
    ? accountsPayable.map((item) => item.idCustomer)
    : [];
  }

  goToHistory() {
    this.$router.push('/folhas-de-pagamento/historico');
  }

  goToSelect() {
    this.$router.push('/folhas-de-pagamento/vincular');
  }

  onChangeCompany(): void {
    this.bank = '';
    this.banks = [];
    if (this.company) {
      this.loadBanks(this.company);
      localStorage.setItem('payroll-upload-company', `${this.company}`);
      this.setLocalStorageExpiration();
    } else {
      this.removeLocalStorage();
    }
  }

  onChangeBank(): void {
    this.shouldUsePaymentDate = this.bankConfigsLoaded
      .get(this.bank)?.use_payment_date_in_payroll ?? false;
    if (!this.shouldUsePaymentDate) this.paymentDate = '';

    if (this.bank) {
      localStorage.setItem('payroll-upload-bank', `${this.bank}`);
      this.setLocalStorageExpiration();
    } else {
      localStorage.removeItem('payroll-upload-bank');
    }
  }

  onChangeVan(): void {
    if (this.selectedVan) {
      localStorage.setItem('payroll-upload-van', `${this.selectedVan}`);
      this.setLocalStorageExpiration();
    } else {
      this.removeLocalStorage();
    }
  }

  setLocalStorageExpiration(): void {
    localStorage.setItem('payroll-upload-expiration', `${new Date().getTime() + 3600000}`);
  }

  getLocalStorage(): void {
    const expirtation = localStorage.getItem('payroll-upload-expiration');
    const companyId = localStorage.getItem('payroll-upload-company');
    const bankId = localStorage.getItem('payroll-upload-bank');
    const vanId = localStorage.getItem('payroll-upload-van');

    const time = new Date().getTime();

    if (expirtation && !Number.isNaN(companyId) && time < +expirtation) {
      if (companyId && !Number.isNaN(companyId)) this.company = +companyId;
      if (bankId) {
        this.bank = bankId;
        this.shouldUsePaymentDate = this.bankConfigsLoaded
          .get(this.bank)?.use_payment_date_in_payroll ?? false;
      }

      if (vanId) {
        this.selectedVan = Number(vanId);
      }
    } else {
      this.removeLocalStorage();
    }
  }

  removeLocalStorage(): void {
    localStorage.removeItem('payroll-upload-expiration');
    localStorage.removeItem('payroll-upload-company');
    localStorage.removeItem('payroll-upload-bank');
    localStorage.removeItem('payroll-upload-van');
  }

  handleUploadError(file: File, error: any) {
    const message = !!error.message && typeof error.message === 'string'
    ? error.message
    : formatErrorForNotification(error);

    this.$notification.error(message ? `${message}` : `${error}`);

    this.dropzone.removeFile(file);
  }

  handleUploadSuccess() {
    this.$notification.success('Arquivo enviado com sucesso!');
    this.removeLocalStorage();
  }

  handleUpload(file: File, xhr: XMLHttpRequest, data: FormData): void {
    xhr.setRequestHeader('token', this.$session.get('token'));
    xhr.setRequestHeader('company-id', `${this.company ?? 0}`);

    data.append('bank_id', `${this.bank}`);
    data.append('payment_date', this.paymentDate);
    data.append('use_payment_date', this.shouldUsePaymentDate ? '1' : '0');

    if (this.selectedVan) {
      data.append('van_id', String(this.selectedVan));
    }

    this.accountsPayable.forEach((idCustomer) => {
      data.append('customer_ids[]', idCustomer);
    });
  }

  async loadCompanies() {
    try {
      this.loadingCompanies = true;

      this.companies = await this.payrollRepository
      .getCompanies();
    } catch (error) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loadingCompanies = false;
    }
  }

  async loadBanks(company: number) {
    try {
      this.loadingBanks = true;
      const bankConfigs = await this.payrollRepository.getAllBanksConfig([company]);

      bankConfigs.forEach((bank) => {
          this.bankConfigsLoaded.set(bank.id_customer, bank);
          this.banks.push({
            value: bank.id_customer,
            text: `${bank.banco} ${bank.agencia} ${bank.conta} - ${bank.name}`,
          });
      });
    } catch (error) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loadingBanks = false;
    }
  }

  async loadVans() {
    try {
      this.loadingVans = true;
      const vans = await this.vanRepository.getVans(true);

      this.vans = vans.map(({ id, description }) => ({
        value: id,
        text: description,
      }));
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar as vans');
    } finally {
      this.loadingVans = false;
    }
  }

  async loadData() {
    await Promise.all([
      this.loadCompanies(),
      this.loadVans(),
    ]);

    const companyId = Number(localStorage.getItem('payroll-upload-company'));
    if (this.company || companyId) {
      await this.loadBanks(this.company || companyId);
    }
  }

  userCanLinkAccountPayableAndUploadPayroll(): boolean {
    return this.$session.get('user_config_send_payroll_file');
  }

  userCanSeeLogs(): boolean {
    return this.$session.get('user_config_list_payroll_file');
  }
}
